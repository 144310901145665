export default {
    computed: {
        checkButtonText() {
            return this.checking ? 'Cancel Check' : 'Check For Controllers';
        },
        checkingText() {
            let checkText = ''
            if (this.frame > 15) checkText += '.'
            if (this.frame > 30) checkText += '.'
            if (this.frame > 45) checkText += '.'
            return checkText;
        },
    },
    data() {
        return {
            checking: false,
            gamepads: {},
            frame: 0,
            loopRunning: false,
            messages: [],
        }
    },
    methods: {
        displayLoop() {
            this.gamepads = navigator.getGamepads();
            this.frame = this.frame === 60 ? 0 : this.frame + 1;
            if (!this.loopRunning) return;
            requestAnimationFrame(this.displayLoop);
        },
        gamepadConnected(event) {
            console.log(event)
            const gamepad = event.gamepad;
            this.gamepads[gamepad.index] = gamepad;
            this.messages.push(
                `${this.gamepads[gamepad.index].id} - Found!`
            )
        },
        gamepadDisconnect(event) {
            const gamepad = event.gamepad;
            this.messages.push(
                `${this.gamepads[gamepad.index].id} - Disconnected!`
            )
            delete this.gamepads[gamepad.index];
        },
        cleanUp() {
            this.checking = false;
            window.removeEventListener("gamepadconnected", this.gamepadConnected);
            window.removeEventListener("gamepaddisconnected", this.gamepadDisconnect);
        },
        toggleCheck() {
           if (this.checking) {
                this.cleanUp();
                return;
            }
            if (!this.checking) {
                this.checking = true;
                this.startDisplay();
            }
        },
        startDisplay() {
            console.log('started')
            window.addEventListener("gamepadconnected", this.gamepadConnected, false);
            window.addEventListener("gamepaddisconnected", this.gamepadDisconnect, false);
            if (!this.loopRunning) {
                this.loopRunning = true;
                this.displayLoop();
            }
        },
    },
    beforeDestroy() {
        this.cleanUp();
        this.loopRunning = false;
    }
}