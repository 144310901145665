<template>
    <div id="controller-container">
        <div class="check-title">
            <button class="check-button" @click="toggleCheck"> 
                {{ checkButtonText }}
            </button>
            <div v-if="checking" class="check-text">
                Checking for controllers{{ checkingText }}
            </div>
            <div v-if="checking" class="messages-display">
                <div v-for="message in messages" :key="message.index">
                    {{ message }}
                </div>
            </div>
        </div>
        <div class="display-container">
            <ControllersDisplay
                :indexNum="1"
                :controllerData="gamepads[0]"
            />
            <ControllersDisplay
                :indexNum="2"
                :controllerData="gamepads[1]"
            />
            <ControllersDisplay
                :indexNum="3"
                :controllerData="gamepads[2]"
            />
            <ControllersDisplay
                :indexNum="4"
                :controllerData="gamepads[3]"
            />
        </div>
    </div>
</template>

<script>
import controllerMixin from "../mixins/gameController.js";
import ControllersDisplay from "@/components/ControllersDisplay.vue"

export default {
    name: 'ControllerComponent',
    components: {ControllersDisplay},
    mixins: [controllerMixin],
    methods: {
        buttonIsPressed(button) {
            return this.pressedButtons.includes(button);
        }
    },
    beforeDestroy() {
        this.cleanUp();
    }
}
</script>

<style scoped>
.check-button {
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 15px;
    width: 30%;
    background: var(--color3);
    cursor: pointer;
}

#controller-container {
    display: flex;
    flex-direction: column;
    width: 700px;
}

.check-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgb(121, 129, 98);
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.check-text {
    font-size: 25px;
    margin: 20px 0px;
}

.display-container {
    display: flex;
    flex-direction: column;
}

</style>