<template>
    <div>
        <ProjectPageTemplate
            projectTitle="GAME CONTROLLERS"
            :imageSource="require('@/assets/images/projectLinks/gameControllerLink.png')"
            :shortDescription="shortDescription"
            :longDescription="description"
            :importedLinkData="techLinksData"
        >
        <template v-slot:main-content>
            <ControllerComponent></ControllerComponent>
        </template>
        </ProjectPageTemplate>
    </div>
    
</template>

<script>
import ControllerComponent from "@/components/GameController";
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import techLinks from '@/data/techLinks.js'

export default {
    name: 'GameController',
    components: {
        ProjectPageTemplate,
        ControllerComponent,
    },
    data() {
        return {
            description: 'Using the gamepad API, the returned data is used to visually display the digital buttons and analogue axis inputs. Up to 4 controllers can be synced and viewed. Event listeners are set to check for any connected or disconnect controllers on the device.',
            shortDescription: 'NOT SUITABLE FOR MOBILE - Connect up to 4 controllers and view their inputs from buttons and axis. The controllers will already need to be synced with the device, either wired or via Bluetooth. Works on Chrome currently but not Firefox.',
            techLinksData: [
                techLinks.html, 
                techLinks.javascript,
                techLinks.css,
            ],
        }
        },
    }
</script>