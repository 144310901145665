import { render, staticRenderFns } from "./AxisDisplay.vue?vue&type=template&id=1c5d1381&scoped=true"
import script from "./AxisDisplay.vue?vue&type=script&lang=js"
export * from "./AxisDisplay.vue?vue&type=script&lang=js"
import style0 from "./AxisDisplay.vue?vue&type=style&index=0&id=1c5d1381&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c5d1381",
  null
  
)

export default component.exports