<template>
    <div v-if="controllerData" class="display-container">
        <div class="header">
            <div class="controller-index">
                {{ `Controller ${indexNum}/4` }}
            </div>
            <div class="sub-heading">
                {{ controllerData?.id }}
            </div>
        </div>
        <div class="controls-container">
            <div class="axis-container">
                <div class="title-text">Axis</div>
                <div class="axis-render">
                    <AxisDisplay
                        v-for="axis in axisList" :key="axis.index"
                        :axisData="axis"
                    />
                </div>
            </div>
            <div class="buttons-container">
                <div class="title-text">Buttons</div>
                <div class="buttons-render">
                    <div
                        class="button"
                        :class="{ pressed: buttonIsPressed(button) }"
                        v-for="button in buttons"
                        :key="button"
                    >
                        {{ button }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AxisDisplay from "@/components/page_elements/AxisDisplay.vue"

export default {
    name: 'ControllersDisplay',
    components: {AxisDisplay},
    props: {
        indexNum: {
            type: Number,
            default: null,
        },
        controllerData: {
            type: Gamepad,
            default: () => {},
        },
    },
    data() {
        return {
            testData: {
                axes: [0, 0, 0, 0],
                buttons: [
                    {pressed: false, touched: false, value: 0},
                    {pressed: false, touched: false, value: 0},
                    {pressed: false, touched: false, value: 0},
                    {pressed: false, touched: false, value: 0},
                    {pressed: false, touched: false, value: 0},
                    {pressed: false, touched: false, value: 0},
                    {pressed: false, touched: false, value: 0},
                    {pressed: false, touched: false, value: 0},
                    {pressed: false, touched: false, value: 0},
                ],
                connected: true,
                id: "WingMan RumblePad (Vendor: 046d Product: c20a)",
                index: 0,
                mapping: '',
                timestamp: 42550.59999999404,
                vibrationActuator: null,
            }
        }
    },
    methods: {
        buttonIsPressed(button) {
            return this.pressedButtons?.includes(button);
        }
    },
    computed: {
        buttons() {
            let buttonsArray = [];
            const numOfButton = this.controllerData?.buttons?.length;
            for(let idx = 0; idx < numOfButton; idx++) {
                buttonsArray.push(idx);
            }
            return buttonsArray;
        },
        pressedButtons() {
            let pressedArray = []
            this.controllerData?.buttons.forEach(button => {
                if (button.touched) {
                    pressedArray.push(this.controllerData?.buttons.indexOf(button));
                }
            })
            return pressedArray;
        },
        axisList() {
            let tempAxis = [];
            let numOfAxis = this.controllerData?.axes.length;
            for(let idx = 0; idx < numOfAxis;) {
                tempAxis.push({
                    xAxis: {
                        id: idx,
                        value: this.controllerData.axes[idx],
                    },
                    yAxis: {
                        id: idx + 1,
                        value: this.controllerData.axes[idx + 1],
                    },
                });
            idx += 2
            }
            return tempAxis;
        },
    },
}
</script>

<style scoped>
.display-container {
    margin-bottom: 20px;
    background: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}

.header {
    height: 70px;
    width: 100%;
    background: rgb(121, 129, 98);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

.controller-index {
    font-weight: bold;
    font-size: 25px;
}

.controls-container {
    display: flex;
}


.buttons-container {
    width: 50%;
}

.buttons-render {
    display: flex;
    flex-wrap: wrap;
    font-size: 20px;
    text-align: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    background: rgb(207, 218, 222);
    width: 35px;
    height: 35px;
    border-radius: 100%;
}

.buttons-container {
    width: 50%;
}

.axis-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
}

.axis-render {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pressed {
    background: green;
    color: white;
}

.title-text{
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}
</style>