<template>
    <div class="axis">
        <img 
            id="crosshair"
            :style="`top: ${axisPos.top}; left: ${axisPos.left}`"
            :src="require('@/assets/images/crossHair.svg')"
        />
    </div>
</template>

<script>
export default {
    name: 'AxisDisplay',
    props: {
        axisData: {
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
            crossHairElement: null,
        }
    },
    computed: {
        axisPos() {
            let xAxis = this?.axisData?.xAxis?.value ?
                        39 + (50 * this.axisData.xAxis.value)
                        : 40;
            let yAxis = this?.axisData?.yAxis?.value ?
                        39 + (50 * this.axisData.yAxis.value)
                        : 40;
            return {
                left: `${xAxis}px`,
                top: `${yAxis}px`,
            }
        },
    },
}

</script>

<style scoped>
.axis {
    width: 100px;
    height: 100px;
    margin: 25px;
    border: 2px black solid;
    border-radius: 100%;
    position: relative;
}

#crosshair {
    position: absolute;
    top: 40px;
    left: 40px;
}
</style>
